import { combineReducers } from "@reduxjs/toolkit";
import AuthSlice from "./AuthSlice";
import CenterSlice from "./CenterSlice";

const rootReducers = combineReducers({
  auth: AuthSlice,
  center: CenterSlice,
});

export default rootReducers;
