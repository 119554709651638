import { gql } from "@apollo/client";

export const GET_ADMIN_DASHBOARD = gql`
  query getAdminPanelDashboard {
    getAdminPanelDashboard {
      payload {
        totalCenters
        totalUsers
      }
    }
  }
`;
