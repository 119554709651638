import { Box, Typography } from "@mui/material";
import React from "react";
import { convertPxToRem } from "utils";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import t from "translate";

type Props = {
  title: string;
  value: number;
};

function PlanLimit({ title, value }: Props) {
  return (
    <Box
      sx={{ display: "flex", gap: convertPxToRem(10), alignItems: "center" }}
    >
      <DoneAllOutlinedIcon color="success" />
      <Typography variant="body1" color="GrayText" key={title}>
        {value} {t(title)}
      </Typography>
    </Box>
  );
}

export default PlanLimit;
