enum colors {
  borderGray = "#D9D9D9",
  textGray = "#808080",
  mainBlue = "#3C6BCF",
  textBlack = "#131A29",
  white = "#FFFFFF",
  boxShadow = "#edeff0",
  imageBg = "#00000050",
  red = "#cd3737",
  green = "#0F8C0D",
  yellow = "#ffc267",
  darkRed = "#c42119",
}

export default colors;
