import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";

import Container from "UI/Container";
import PageHeader from "UI/PageHeader";
import DashboardCard from "./components/Card";
import { useQuery } from "@apollo/client";
import { GET_ADMIN_DASHBOARD } from "./api";
import InnerLoader from "components/InnerLoader";

const Dashboard = () => {
  const { data, loading, refetch } = useQuery(GET_ADMIN_DASHBOARD);

  useEffect(() => {
    refetch();
  }, []);

  const { totalCenters, totalUsers } =
    data?.getAdminPanelDashboard?.payload || {};

  return (
    <Container>
      <PageHeader title="dashboard" />
      {loading ? (
        <InnerLoader />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6} sm={6}>
            <DashboardCard
              icon={AccountBalanceOutlinedIcon}
              title="total centers"
              total={totalCenters}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={6}>
            <DashboardCard
              icon={PeopleOutlinedIcon}
              title="total users"
              total={totalUsers}
            />
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default Dashboard;
