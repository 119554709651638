import { createSlice } from "@reduxjs/toolkit";
import { ICountry, IUser } from "interfaces/Redux";
import { removeDuplicateCountries } from "utils";

interface IState {
  token: string;
  user: IUser | null;
  countries: ICountry[];
  lang?: string;
  words: { [key: string]: string };
}

const initialState: IState = {
  token: "",
  user: null,
  countries: [],
  words: {},
  lang: "",
};

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signInAction(state: IState, action) {
      state.token = action.payload?.token;
      state.user = action.payload?.user;
    },
    signOut(state) {
      state.token = "";
      state.user = null;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setWords(state, action) {
      state.words = action.payload;
    },
    setLang(state, action) {
      state.lang = action.payload;
    },
  },
});

export default auth.reducer;

export const { signInAction, signOut, setWords, setUser, setLang } =
  auth.actions;
