import React, { useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from "@mui/material";
import t from "translate";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { UPDATE_PLAN } from "pages/Plans/api";
import Loader from "components/Loader";
import { toast } from "react-toastify";
import { PlanType } from "pages/Plans/interfaces";
import { getOnlyUpdatedFields } from "utils";

interface Props {
  open: boolean;
  refetch: VoidFunction;
  onClose: VoidFunction;
  selectedPlan: PlanType;
}

const UpdatePlanModal = ({ open, refetch, onClose, selectedPlan }: Props) => {
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [updatePlan, { data, loading }] = useMutation(UPDATE_PLAN);

  const handleUpdate = (data: FieldValues) => {
    const { title, price, students, groups, employees } = data;

    const initialValues = {
      title: selectedPlan?.title,
      price: selectedPlan?.price?.toString(),
      groups: selectedPlan?.limits?.groups?.toString(),
      students: selectedPlan?.limits?.students?.toString(),
      employees: selectedPlan?.limits?.employees?.toString(),
    };

    const updatedFields = getOnlyUpdatedFields(initialValues, data);

    if (Object.values(updatedFields)?.length) {
      updatePlan({
        variables: {
          planId: selectedPlan?._id,
          data: {
            title,
            price: parseInt(price),
            limits: {
              groups: parseInt(groups),
              students: parseInt(students),
              employees: parseInt(employees),
            },
          },
        },
      });
    }
  };

  useEffect(() => {
    reset({
      title: selectedPlan?.title,
      price: selectedPlan?.price?.toString(),
      groups: selectedPlan?.limits?.groups?.toString(),
      students: selectedPlan?.limits?.students?.toString(),
      employees: selectedPlan?.limits?.employees?.toString(),
    });
  }, [selectedPlan]);

  useEffect(() => {
    if (!loading && data) {
      onClose();
      reset({});
      refetch();
      toast.success(t("Plan updated successfully!"));
    }
  }, [data, loading]);

  return (
    <>
      {loading && <Loader />}
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{t("update plan")}</DialogTitle>
        <DialogContent>
          <Box gap={2} display="flex" component="form" flexDirection="column">
            <Controller
              name="title"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => {
                return (
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      label={t("title")}
                      {...field}
                      error={!!errors[field.name]}
                      helperText={
                        !!errors[field.name] && t("Please input plan title!")
                      }
                    />
                  </FormControl>
                );
              }}
            />
            <Controller
              name="students"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => {
                return (
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      type="number"
                      defaultValue={0}
                      label={t("students")}
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      InputProps={{ inputProps: { min: 0 } }}
                      {...field}
                      error={!!errors[field.name]}
                      helperText={
                        !!errors[field.name] &&
                        t("Please input students amount!")
                      }
                    />
                  </FormControl>
                );
              }}
            />
            <Controller
              name="groups"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => {
                return (
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      type="number"
                      defaultValue={0}
                      label={t("groups")}
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      InputProps={{ inputProps: { min: 0 } }}
                      {...field}
                      error={!!errors[field.name]}
                      helperText={
                        !!errors[field.name] && t("Please input groups amount!")
                      }
                    />
                  </FormControl>
                );
              }}
            />
            <Controller
              name="employees"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => {
                return (
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      type="number"
                      defaultValue={0}
                      label={t("employees")}
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      InputProps={{ inputProps: { min: 0 } }}
                      {...field}
                      error={!!errors[field.name]}
                      helperText={
                        !!errors[field.name] &&
                        t("Please input employees amount!")
                      }
                    />
                  </FormControl>
                );
              }}
            />
            <Controller
              name="price"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => {
                return (
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      type="number"
                      defaultValue={0}
                      label={t("price")}
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      InputProps={{ inputProps: { min: 0 } }}
                      {...field}
                      error={!!errors[field.name]}
                      helperText={
                        !!errors[field.name] && t("Please input price amount!")
                      }
                    />
                  </FormControl>
                );
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            color="inherit"
            onClick={onClose}
            variant="contained"
          >
            {t("cancel")}
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit(handleUpdate)}
          >
            {t("update")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdatePlanModal;
