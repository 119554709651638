import { Box, Button, Paper, Typography } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

import t from "translate";
import PlanLimit from "../PlanLimit";
import { PlanType } from "pages/Plans/interfaces";

export type PlanPropsType = {
  plan: PlanType;
  onUpdate: (plan: PlanType) => void;
  onDelete: (plan: PlanType) => void;
};

function PlanCard({ plan, onUpdate, onDelete }: PlanPropsType) {
  const { title, limits, price, currency } = plan;

  return (
    <Paper elevation={1} sx={{ padding: 2, height: "100%" }}>
      <Box
        gap={2}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="h5" textAlign="center">
            {title}
          </Typography>
          <Typography variant="h5" textAlign="center">
            {price?.toLocaleString("FI-fi")} {currency}
          </Typography>
        </Box>
        <Box gap={2} display="flex" flexDirection="column">
          {Object.entries(limits).map((limit, i) => (
            <PlanLimit title={limit[0]} value={limit[1]} key={i} />
          ))}
        </Box>
        <Box gap={1} display="flex">
          <Button
            fullWidth
            size="small"
            color="error"
            variant="outlined"
            onClick={() => onDelete(plan)}
            startIcon={<DeleteOutlinedIcon />}
          >
            {t("delete")}
          </Button>
          <Button
            fullWidth
            size="small"
            variant="outlined"
            onClick={() => onUpdate(plan)}
            startIcon={<EditOutlinedIcon />}
          >
            {t("edit")}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}

export default PlanCard;
