import { gql } from "@apollo/client";

export const GET_LANGUAGES = gql`
  query getLanguages {
    getLanguages {
      payload {
        _id
        lang
        words
      }
    }
  }
`;

export const UPDATE_LANG = gql`
  mutation updateLanguage($languageId: ID!, $data: LanguageUpdateInput!) {
    updateLanguage(languageId: $languageId, data: $data) {
      payload {
        _id
      }
    }
  }
`;
