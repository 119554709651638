import React from "react";
import {
  Box,
  Button,
  ButtonPropsColorOverrides,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  SvgIconPropsColorOverrides,
  SvgIconTypeMap,
} from "@mui/material";
import t from "translate";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { convertPxToRem } from "utils";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { OverridableStringUnion } from "@mui/types";

interface Props {
  open: boolean;
  icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  iconColor?: OverridableStringUnion<
    | "error"
    | "disabled"
    | "action"
    | "inherit"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning",
    SvgIconPropsColorOverrides
  >;
  title?: string;
  btnLabel?: string;
  btnColor?: OverridableStringUnion<
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning",
    ButtonPropsColorOverrides
  >;
  description?: string;
  onClose: VoidFunction;
  onConfirm: VoidFunction;
}

const ConfirmModal = ({
  open,
  title,
  iconColor,
  icon: Icon,
  onClose,
  onConfirm,
  btnLabel,
  btnColor,
  description,
}: Props) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Box display="flex" alignItems="center" gap={convertPxToRem(14)}>
          {Icon ? (
            <Icon color={iconColor} sx={{ fontSize: convertPxToRem(60) }} />
          ) : (
            <WarningAmberRoundedIcon
              color={iconColor || "error"}
              sx={{ fontSize: convertPxToRem(60) }}
            />
          )}
          <Box gap={convertPxToRem(4)} display="flex" flexDirection="column">
            <DialogTitle variant="subtitle1" id="alert-dialog-title">
              {t(title || "Do you want to delete?")}
            </DialogTitle>
            <DialogContentText
              variant="subtitle2"
              id="alert-dialog-description"
            >
              {t(
                description ||
                  "If you delete it, you won't be able to restore it. Please make sure before deleting."
              )}
            </DialogContentText>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="small"
          color="inherit"
          fullWidth
          onClick={onClose}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="outlined"
          fullWidth
          size="small"
          color={btnColor || "error"}
          onClick={onConfirm}
        >
          {t(btnLabel || "delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
