import React from "react";
import { Typography } from "@mui/material";
import Style from "./DescriptionText.style";

type Props = {
  text: string;
  lines?: number;
};

function DescriptionText({ text, lines }: Props) {
  return (
    <Style lines={lines || 2}>
      <Typography
        className="description"
        variant="body2"
        component="div"
        color="text.secondary"
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    </Style>
  );
}

export default DescriptionText;
