import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import PlanCard from "./components/Card";
import Container from "UI/Container";
import { DELETE_PLAN, GET_PLANS } from "./api";
import PageHeader from "UI/PageHeader";
import InnerLoader from "components/InnerLoader";
import NoData from "UI/NoData";
import CustomPlanCard from "./components/CustomCard";
import { Button, Grid } from "@mui/material";
import { CUSTOM_PLAN_INITIAL_STUDENTS_AMOUNT } from "./constants";
import { PlanType } from "./interfaces";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import t from "translate";
import CreatePlanModal from "./components/CreatePlanModal";
import UpdatePlanModal from "./components/UpdatePlanModal";
import UpdateBasePlanModal from "./components/UpdateBasePlanModal";
import ConfirmModal from "components/ConfirmModal";
import { toast } from "react-toastify";

function Plans() {
  const [selectedPlan, setSelectedPlan] = useState<PlanType | any>({});
  const [isCreateModalVisible, setIsCreateModalVisible] =
    useState<boolean>(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] =
    useState<boolean>(false);
  const [isBaseUpdateModalVisible, setIsBaseUpdateModalVisible] =
    useState<boolean>(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);

  const { data, loading, refetch } = useQuery(GET_PLANS);

  const [deletePlan, { data: deleteData, loading: deleteLoading }] =
    useMutation(DELETE_PLAN);

  const toggleCreateModalVisible = () =>
    setIsCreateModalVisible((prev) => !prev);
  const toggleUpdateModalVisible = () =>
    setIsUpdateModalVisible((prev) => !prev);
  const toggleBaseUpdateModalVisible = () =>
    setIsBaseUpdateModalVisible((prev) => !prev);
  const toggleDeleteModalVisible = () =>
    setIsDeleteModalVisible((prev) => !prev);

  const handleDelete = () => {
    deletePlan({
      variables: {
        planId: selectedPlan?._id,
      },
    });
  };

  const plans: PlanType[] = data?.plans?.payload || [];
  const basePlan: PlanType = data?.basePlan?.payload || {};

  const { __typename, ...limitsWithoutTypename } = basePlan?.limits || {};

  const customPlan: PlanType | any = basePlan || {};

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (!deleteLoading && deleteData) {
      toast.success("Plan deleted successfully!");
      refetch();
    }
  }, [deleteData, deleteLoading]);

  return (
    <Container>
      <>
        <PageHeader title="plans">
          <Button
            variant="contained"
            startIcon={<AddRoundedIcon />}
            onClick={toggleCreateModalVisible}
          >
            {t("create plan")}
          </Button>
        </PageHeader>
        {loading ? (
          <InnerLoader />
        ) : plans?.length || Object.values(basePlan)?.length ? (
          <Grid spacing={2} container>
            {plans.map(({ limits, ...plan }) => {
              const { __typename, ...limitsWithoutTypename } = limits;
              return (
                <Grid key={plan?._id} item xs={12} sm={6} md={4} lg={3}>
                  <PlanCard
                    plan={{ limits: limitsWithoutTypename, ...plan }}
                    onUpdate={(plan) => {
                      setSelectedPlan(plan);
                      toggleUpdateModalVisible();
                    }}
                    onDelete={(plan) => {
                      setSelectedPlan(plan);
                      toggleDeleteModalVisible();
                    }}
                  />
                </Grid>
              );
            })}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomPlanCard
                onDelete={() => {}}
                onUpdate={toggleBaseUpdateModalVisible}
                plan={{ ...customPlan, limits: limitsWithoutTypename }}
                basePlan={{
                  ...basePlan,
                  initialStudentsAmount: CUSTOM_PLAN_INITIAL_STUDENTS_AMOUNT,
                }}
              />
            </Grid>
          </Grid>
        ) : (
          <NoData />
        )}
      </>
      <CreatePlanModal
        refetch={refetch}
        open={isCreateModalVisible}
        onClose={toggleCreateModalVisible}
      />
      <UpdatePlanModal
        refetch={refetch}
        selectedPlan={selectedPlan}
        open={isUpdateModalVisible}
        onClose={toggleUpdateModalVisible}
      />
      <UpdateBasePlanModal
        refetch={refetch}
        basePlan={basePlan}
        open={isBaseUpdateModalVisible}
        onClose={toggleBaseUpdateModalVisible}
      />
      <ConfirmModal
        onConfirm={handleDelete}
        open={isDeleteModalVisible}
        onClose={toggleDeleteModalVisible}
      />
    </Container>
  );
}

export default Plans;
