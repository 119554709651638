import { RangePickerProps } from "antd/lib/date-picker";
import { ICountry } from "interfaces/Redux";
import moment from "moment";

export function convertPxToRem(px: number): string {
  return px / 16 + "rem";
}

export const acceptedFilesType = ["image/jpg", "image/png", "image/jpeg"];

export const removeDuplicateCountries = (countries: ICountry[]) => {
  const uniqueCountries: string[] = [];
  return countries.filter((country) => {
    const isDuplicate = uniqueCountries.includes(country.country);
    if (!isDuplicate) {
      uniqueCountries.push(country.country);
      return true;
    }
    return false;
  });
};

export const weekDays = [
  { label: "Mon", value: "mon" },
  { label: "Tue", value: "tue" },
  { label: "Wed", value: "wed" },
  { label: "Thu", value: "thu" },
  { label: "Fri", value: "fri" },
  { label: "Sat", value: "sat" },
  { label: "Sun", value: "sun" },
];

export const monthsLabel = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Avg",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const TimeFormat = "HH:mm";
export const DateMonthFormat = "MMM YYYY";
export const DateFormat = "DD.MM.YYYY";
export const DateAndTimeFormat = "DD.MM.YYYY HH:mm";
export const DayFormat = "ddd";
export const TimepickerFormat = moment("00:00", TimeFormat);
export type TFunction = (value: string) => void;

export const getOnlyUpdatedFields = function (
  oldFilds: any,
  updatedFilds: any
) {
  let result = {};
  for (let x in updatedFilds) {
    if (oldFilds[x] !== updatedFilds[x]) {
      result = { ...result, [x]: updatedFilds[x] };
    }
  }
  return result;
};

export const disabledDate: RangePickerProps["disabledDate"] = (current) => {
  return current && current > moment().endOf("day");
};

export const getSlicedDataForPagination = (
  size: number,
  currentPage: number,
  items: any[]
) => {
  const start = (currentPage - 1) * size;
  return items.slice(start, start + size);
};

export const removeTypeName = (obj: any) => {
  const { __typename, ...fields } = obj;
  const keys = Object.keys(fields);
  const res: any = {};
  keys.forEach((key: string) => {
    if (typeof fields[key] === "object") {
      res[key] = removeTypeName(fields[key]);
    } else {
      res[key] = fields[key];
    }
  });
  return res;
};

export const formatterNumber = (val: any): any => {
  const clearedVal = `${val}`.replace(/[^0-9.-]/g, "");
  return clearedVal ? parseInt(clearedVal).toLocaleString() : "";
};

export const parserNumber = (val: any) => {
  return val.replace(/[^0-9]/g, "");
};
