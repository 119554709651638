import styled from "styled-components";
import { convertPxToRem } from "utils";

export default styled.div<{ lines: number }>`
  width: 100%;

  .description {
    height: 100%;
    max-height: ${({ lines }) => convertPxToRem(lines * 20)};
    display: -webkit-box;
    -webkit-line-clamp: ${({ lines }) => lines};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .description br {
    display: inline-block;
    width: ${convertPxToRem(6)};
  }

  .description p > br {
    display: none;
  }

  .description * {
    margin: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: inherit;
    line-height: 1.43;
  }
`;
