import React, { useEffect, useState } from "react";
import Container from "UI/Container";
import { useQuery } from "@apollo/client";
import CenterCard from "./components/Card";
import PayModal from "./components/PayModal";
import { GET_ALL_CENTERS } from "./api";
import { ICenter } from "interfaces/Centers";
import NoData from "UI/NoData";
import InnerLoader from "components/InnerLoader";
import PageHeader from "UI/PageHeader";
import { Grid } from "@mui/material";
import SMSSettingsSetModal from "./components/AssignKeyModal";

function Centers() {
  const [isPayModalVisible, setIsPayModalVisible] = useState(false);
  const [isSMSConfigModalVisible, setIsSMSConfigModalVisible] = useState(false);
  const [selectedCenterId, setSelectedCenterId] = useState("");
  const [selectedCenterPlanPrice, setSelectedCenterPlanPrice] = useState(0);

  const { loading, data, refetch } = useQuery(GET_ALL_CENTERS);

  const togglePayModalVisible = () => setIsPayModalVisible((prev) => !prev);
  const toggleSMSConfigModalVisible = () =>
    setIsSMSConfigModalVisible((prev) => !prev);

  const handlePay = (_id: string, plan: any) => {
    setSelectedCenterId(_id);
    setSelectedCenterPlanPrice(plan?.price);
    togglePayModalVisible();
  };

  const handleAddSMSConfig = (_id: string) => {
    setSelectedCenterId(_id);
    toggleSMSConfigModalVisible();
  };

  const centers: ICenter[] = data?.getAllCenters?.payload || [];

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Container>
      <PageHeader title="centers" />
      {loading ? (
        <InnerLoader />
      ) : centers?.length ? (
        <Grid container spacing={2}>
          {centers.map((center) => (
            <Grid item xs={12} sm={6} md={4} key={center?._id}>
              <CenterCard
                center={center}
                refetch={refetch}
                onPay={() => handlePay(center._id, center?.plan)}
                onAddKey={() => handleAddSMSConfig(center._id)}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <NoData />
      )}
      <PayModal
        open={isPayModalVisible}
        selectedCenterId={selectedCenterId}
        onClose={togglePayModalVisible}
        selectedCenterPlanPrice={selectedCenterPlanPrice}
      />
      <SMSSettingsSetModal
        open={isSMSConfigModalVisible}
        selectedCenterId={selectedCenterId}
        onClose={toggleSMSConfigModalVisible}
      />
    </Container>
  );
}

export default Centers;
