import React, { useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import t from "translate";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { UPDATE_PLAN } from "pages/Plans/api";
import Loader from "components/Loader";
import { toast } from "react-toastify";
import { PlanType } from "pages/Plans/interfaces";

interface Props {
  open: boolean;
  refetch: VoidFunction;
  onClose: VoidFunction;
  basePlan: PlanType;
}

const UpdateBasePlanModal = ({ open, refetch, onClose, basePlan }: Props) => {
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [updatePlan, { data, loading }] = useMutation(UPDATE_PLAN);

  const handleUpdate = (data: FieldValues) => {
    const values = Object.keys(data);
    let updatedPrices: any[] = [];

    values?.forEach((item) => {
      if (item?.startsWith("upTo")) {
        updatedPrices.push({
          upTo: parseInt(data[item]),
          price: parseInt(data["price-" + parseInt(item?.split("-")[1])]),
        });
      }
    });

    if (Object.values(updatedPrices)?.length) {
      updatePlan({
        variables: {
          planId: basePlan?._id,
          data: {
            prices: updatedPrices,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (basePlan?.prices?.length) {
      let prices: any = {};
      basePlan?.prices?.map((price) => {
        prices[`upTo-${price?.upTo}`] = price?.upTo;
        prices[`price-${price?.upTo}`] = price?.price;
        return price;
      });

      reset(prices);
    }
  }, [basePlan]);

  useEffect(() => {
    if (!loading && data) {
      onClose();
      reset({});
      refetch();
      toast.success(t("Plan updated successfully!"));
    }
  }, [data, loading]);

  return (
    <>
      {loading && <Loader />}
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{t("update base plan")}</DialogTitle>
        <DialogContent>
          <Box gap={2} display="flex" component="form" flexDirection="column">
            {basePlan?.prices?.map((price) => (
              <Grid key={price?.upTo} container spacing={2}>
                <Grid item xs={6}>
                  <Controller
                    name={`upTo-${price?.upTo}`}
                    rules={{
                      required: true,
                    }}
                    control={control}
                    render={({ field }) => {
                      return (
                        <FormControl fullWidth>
                          <TextField
                            fullWidth
                            type="number"
                            label={t("up to")}
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            {...field}
                            error={!!errors[field.name]}
                            helperText={
                              !!errors[field.name] && t("Please input price!")
                            }
                          />
                        </FormControl>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    key={price?.upTo}
                    name={`price-${price?.upTo}`}
                    rules={{
                      required: true,
                    }}
                    control={control}
                    render={({ field }) => {
                      return (
                        <FormControl fullWidth>
                          <TextField
                            fullWidth
                            type="number"
                            label={t("price")}
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            {...field}
                            error={!!errors[field.name]}
                            helperText={
                              !!errors[field.name] && t("Please input price!")
                            }
                          />
                        </FormControl>
                      );
                    }}
                  />
                </Grid>
              </Grid>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            color="inherit"
            onClick={onClose}
            variant="contained"
          >
            {t("cancel")}
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit(handleUpdate)}
          >
            {t("update")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdateBasePlanModal;
