import styled from "styled-components";
import { convertPxToRem } from "utils";
import { HEADER_HEIGHT, HEADER_RESPONSIVE_HEIGHT } from "./constants/style";

export default styled.nav`
  padding: ${convertPxToRem(16)} ${convertPxToRem(24)};
  height: ${convertPxToRem(HEADER_HEIGHT)};

  @media (max-width: 900px) {
    height: ${convertPxToRem(HEADER_RESPONSIVE_HEIGHT)};
  }
`;
