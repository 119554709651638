import { colors } from "@mui/material";
import styled from "styled-components";
import { convertPxToRem } from "utils";

export default styled.div`
  .card__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${convertPxToRem(64)};
    height: ${convertPxToRem(64)};
    border-radius: 50%;
    background-color: ${colors.blue[50]};
  }

  @media (max-width: 576px) {
    .card__title {
      font-size: ${convertPxToRem(26)};
    }
    .card__icon-wrapper {
      width: ${convertPxToRem(56)};
      height: ${convertPxToRem(56)};

      svg {
        font-size: ${convertPxToRem(30)};
      }
    }
  }
`;
