import { SyntheticEvent, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Container from "UI/Container";
import Style from "./Style";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Input,
  Typography,
} from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { convertPxToRem } from "utils";
import t from "translate";
import PageHeader from "UI/PageHeader";
import NoData from "UI/NoData";
import { toast } from "react-toastify";
import Loader from "components/Loader";
import { GET_LANGUAGES, UPDATE_LANG } from "./api";

function Langs() {
  const [selectedLangId, setSelectedLangId] = useState<string>("");
  const [updatedWords, setUpdatedWords] = useState<string>("");
  const [expanded, setExpanded] = useState<string | false>(false);

  const { data, loading, refetch } = useQuery(GET_LANGUAGES);

  const [updateLang, { data: updateData, loading: updateLoading }] =
    useMutation(UPDATE_LANG);

  const handleAccordionChange =
    (panel: string) => (_: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleCancel = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setSelectedLangId("");
    setUpdatedWords("");
    event.stopPropagation();
  };

  const handleSave = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    _id: string
  ) => {
    setSelectedLangId(_id);
    handleUpdateLang();
    event.stopPropagation();
  };

  const handleUpdate = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    _id: string
  ) => {
    setSelectedLangId(_id);
    setUpdatedWords("");
    event.stopPropagation();
  };

  const handleUpdateLang = () => {
    if (updatedWords?.length) {
      console.log(JSON.parse(updatedWords));
      updateLang({
        variables: {
          languageId: selectedLangId,
          data: { words: JSON.parse(updatedWords) },
        },
      });
    }
  };

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (!updateLoading && updateData) {
      setSelectedLangId("");
      setUpdatedWords("");
      refetch();
      toast.success("Language words updated successfully!");
    }
  }, [updateData, updateLoading]);

  const langs = data?.getLanguages?.payload || [];

  return (
    <Style>
      <Container>
        {(loading || updateLoading) && <Loader />}
        <PageHeader title="languages" />
        {langs?.length ? (
          <>
            {langs?.map(({ lang, words, _id }: any) => {
              const formattedWords = JSON.stringify(words, null, "\t");
              return (
                <Accordion
                  key={_id}
                  elevation={0}
                  disableGutters
                  expanded={expanded === _id}
                  onChange={handleAccordionChange(_id)}
                  TransitionProps={{ unmountOnExit: true }}
                >
                  <AccordionSummary
                    id={`${_id}bh-header`}
                    aria-controls={`${_id}bh-content`}
                    expandIcon={
                      <ArrowForwardIosSharpIcon
                        sx={{ fontSize: convertPxToRem(16) }}
                      />
                    }
                  >
                    <Box
                      width="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography textTransform="uppercase">{lang}</Typography>
                      {selectedLangId === _id ? (
                        <Box gap={1} display="flex">
                          <Button
                            size="small"
                            variant="outlined"
                            onClick={handleCancel}
                          >
                            {t("cancel")}
                          </Button>
                          <Button
                            size="small"
                            variant="contained"
                            onClick={(event) => handleSave(event, _id)}
                          >
                            {t("save")}
                          </Button>
                        </Box>
                      ) : (
                        <Button
                          size="small"
                          onClick={(event) => handleUpdate(event, _id)}
                        >
                          {t("update")}
                        </Button>
                      )}
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    {selectedLangId === _id ? (
                      <Input
                        rows={18}
                        fullWidth
                        multiline
                        defaultValue={formattedWords}
                        onChange={(e) => setUpdatedWords(e.target.value)}
                      />
                    ) : (
                      <Typography
                        component="pre"
                        overflow="auto"
                        variant="body1"
                        maxHeight={convertPxToRem(425)}
                      >
                        {formattedWords}
                      </Typography>
                    )}
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </>
        ) : (
          <NoData />
        )}
      </Container>
    </Style>
  );
}

export default Langs;
