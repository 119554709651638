import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "hooks/redux";
import { signOut } from "store/reducer/AuthSlice";
import { header_routes } from "routes/Header";
import Style from "./Style";
import t from "translate";
import { removeCenter } from "store/reducer/CenterSlice";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { ReactComponent as Logo } from "./assets/logo.svg";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { convertPxToRem } from "utils";

const Header = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const sidebarVisibleToggler = () => setIsSidebarVisible((prev) => !prev);

  const handleSidebarLinkClick = (path: string) => {
    navigate(path);
    sidebarVisibleToggler();
  };

  const handleSignOut = () => {
    dispatch(signOut());
    dispatch(removeCenter());
    navigate("/");
  };

  return (
    <>
      <AppBar
        color="inherit"
        position="relative"
        sx={{ zIndex: 1, maxWidth: "100vw" }}
      >
        <Style>
          <Drawer open={isSidebarVisible} onClose={sidebarVisibleToggler}>
            <List sx={{ width: convertPxToRem(350) }}>
              <ListItem>
                <ListItemIcon>
                  <Logo width={convertPxToRem(150)} />
                </ListItemIcon>
              </ListItem>
              <Divider sx={{ my: convertPxToRem(16) }} />
              {header_routes.map((route) => (
                <ListItem key={route.key} disablePadding>
                  <ListItemButton
                    onClick={() => handleSidebarLinkClick(route.path)}
                  >
                    <ListItemIcon>{route.icon}</ListItemIcon>
                    <ListItemText primary={t(route.title)} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Drawer>
          <Box
            sx={{
              gap: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={sidebarVisibleToggler}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Logo width={convertPxToRem(110)} />
              <Box
                sx={{
                  flexGrow: 1,
                  ml: convertPxToRem(20),
                  display: { xs: "none", md: "flex" },
                }}
              >
                {header_routes.map(({ key, path, title }) => (
                  <Typography
                    component={Link}
                    mx={convertPxToRem(8)}
                    textTransform="capitalize"
                    to={path}
                    key={key}
                  >
                    {t(title)}
                  </Typography>
                ))}
              </Box>
            </Box>
            <Button
              size="small"
              color="inherit"
              onClick={handleSignOut}
              startIcon={<LogoutOutlinedIcon />}
            >
              {t("sign out")}
            </Button>
          </Box>
        </Style>
      </AppBar>
    </>
  );
};
export default Header;
