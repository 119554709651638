import { useMutation } from "@apollo/client";
import Loader from "components/Loader";
import { useEffect } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from "@mui/material";
import t from "translate";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { ADD_SMS_CONFIGS } from "./api";

type Props = {
  open: boolean;
  onClose: VoidFunction;
  selectedCenterId: string;
};

function SMSSettingsSetModal({ open, onClose, selectedCenterId }: Props) {
  const [addSMSConfigs, { loading, data }] = useMutation(ADD_SMS_CONFIGS);

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleAdd = ({ secret, deviceId }: FieldValues) => {
    addSMSConfigs({
      variables: {
        center: selectedCenterId,
        secret,
        deviceId,
      },
    });
  };

  useEffect(() => {
    if (!loading && data) {
      onClose();
      reset({});
      toast.success("Muvaqiyatli to'landi!");
    }
  }, [loading, data]);

  return (
    <>
      {loading && <Loader />}
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{t("Add SMS configs")}</DialogTitle>
        <DialogContent>
          <Controller
            name="secret"
            rules={{
              required: true,
            }}
            control={control}
            render={({ field }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    label={t("Secret key")}
                    {...field}
                    error={!!errors[field.name]}
                    helperText={
                      !!errors[field.name] && t("Please input secret!")
                    }
                  />
                </FormControl>
              );
            }}
          />
          <Controller
            name="deviceId"
            rules={{
              required: true,
            }}
            control={control}
            render={({ field }) => {
              return (
                <FormControl fullWidth margin="normal">
                  <TextField
                    label={t("Device ID")}
                    {...field}
                    error={!!errors[field.name]}
                    helperText={
                      !!errors[field.name] && t("Please input device ID!")
                    }
                  />
                </FormControl>
              );
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            color="inherit"
            onClick={onClose}
            variant="contained"
          >
            {t("cancel")}
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit(handleAdd)}
          >
            {t("Add")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default SMSSettingsSetModal;
