import styled from "styled-components";
import colors from "styles/colors";

const { mainBlue, borderGray } = colors;

export default styled.div`
  .updateBtn {
    background-color: ${mainBlue};
    border: 1px solid ${mainBlue};
    color: white;
    border-radius: 4px;
  }

  .cancelBtn {
    margin-right: 10px;
    border-radius: 4px;
    border: 1px solid ${borderGray};
  }

  .words {
    height: 450px;
  }
`;
