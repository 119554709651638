import { useEffect, useState } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Box,
  Button,
  FormControl,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import t from "translate";
import PlanLimit from "../PlanLimit";
import { PlanPropsType } from "../Card";
import {
  calculateGroupsAndEmployeesLimit,
  calculatePriceByStudentsAmount,
} from "pages/Plans/helpers";
import { BasePlanPriceListType } from "pages/Plans/interfaces";

interface Props extends PlanPropsType {
  basePlan: {
    prices: BasePlanPriceListType[];
    employees: number;
    groups: number;
    price: number;
    limits: { [key: string]: number };
    initialStudentsAmount: number;
  };
  onUpdate: (plan: any) => void;
}

function CustomPlanCard({
  basePlan,
  onUpdate,
  plan: { price, limits: defaultLimits, currency },
}: Props) {
  const [planPrice, setPricePrice] = useState<number>(price);
  const [limits, setLimits] = useState(defaultLimits);
  const { prices, employees, groups, initialStudentsAmount } = basePlan;

  const handleStudentsAmountChange = (studentsAmount: number) => {
    const amount =
      studentsAmount > initialStudentsAmount
        ? studentsAmount
        : initialStudentsAmount;
    const totalPrice = calculatePriceByStudentsAmount(amount, prices);
    const teachersAndGroupsLimit = calculateGroupsAndEmployeesLimit({
      amount: amount || 0,
      employees,
      groups,
    });
    setPricePrice(totalPrice);
    setLimits(teachersAndGroupsLimit);
  };

  useEffect(() => {
    handleStudentsAmountChange(initialStudentsAmount);
  }, [basePlan]);

  return (
    <Paper elevation={1}>
      <Box
        gap={2}
        padding={2}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="h5" textAlign="center">
            {t("custom")}
          </Typography>
          <FormControl fullWidth sx={{ mt: 1 }}>
            <TextField
              fullWidth
              size="small"
              type="number"
              label={t("students")}
              defaultValue={initialStudentsAmount}
              onChange={(e) => handleStudentsAmountChange(+e.target.value)}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              InputProps={{ inputProps: { min: initialStudentsAmount } }}
            />
          </FormControl>
        </Box>
        <Typography>
          {planPrice?.toLocaleString("FI-fi")} {currency}
        </Typography>
        <Box gap={2} display="flex" flexDirection="column">
          {Object.entries(limits).map((limit, i) => (
            <PlanLimit title={limit[0]} value={limit[1]} key={i} />
          ))}
        </Box>
        <Button
          fullWidth
          size="small"
          variant="outlined"
          onClick={() => onUpdate(basePlan)}
          startIcon={<EditOutlinedIcon />}
        >
          {t("edit")}
        </Button>
      </Box>
    </Paper>
  );
}

export default CustomPlanCard;
