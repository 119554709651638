import { STUDENTS_IN_ONE_GROUP } from "../constants";
import { BasePlanPriceListType } from "../interfaces";

type GroupsAndEmployeesLimitProps = {
  amount: number;
  employees: number;
  groups: number;
};

export const calculateGroupsAndEmployeesLimit = ({
  amount,
  employees,
  groups,
}: GroupsAndEmployeesLimitProps) => {
  const studentsAfterDivide = Math.ceil(amount / STUDENTS_IN_ONE_GROUP);
  return {
    employees: studentsAfterDivide * employees,
    groups: studentsAfterDivide * groups,
  };
};

export const calculatePriceByStudentsAmount = (
  studentsAmount: number,
  prices: BasePlanPriceListType[]
) => {
  const { totalPrice, amount } = prices.reduce(
    (acc, { price, upTo }, i, arr) => {
      const { amount } = acc;
      const prevLimitCeil = arr[i - 1]?.upTo || 0;
      if (!amount) {
        return acc;
      }
      if (amount + prevLimitCeil > upTo) {
        acc.amount -= upTo - prevLimitCeil;
        acc.totalPrice += (upTo - prevLimitCeil) * price;
        return acc;
      }

      acc.totalPrice += amount * price;
      acc.amount = 0;
      return acc;
    },
    {
      amount: studentsAmount,
      totalPrice: 0,
    }
  );
  if (amount) {
    return totalPrice + prices.slice(-1)[0].price * amount;
  }
  return totalPrice;
};
