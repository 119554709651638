import { store } from "store";

function t(word: string) {
  const words = store.getState()?.auth?.words ?? {};

  const translatedWord = words[word];

  if (translatedWord) {
    return translatedWord;
  }
  return word;
}

export default t;
