import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { convertPxToRem } from "utils";

function InnerLoader() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        py: convertPxToRem(20),
        mt: convertPxToRem(30),
      }}
    >
      <CircularProgress />
    </Box>
  );
}

export default InnerLoader;
