import { useMutation } from "@apollo/client";
import Loader from "components/Loader";
import { useEffect } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import t from "translate";
import { Controller, FieldValue, FieldValues, useForm } from "react-hook-form";
import { CREATE_PLAN_PAYMENT } from "pages/Centers/api";

type Props = {
  open: boolean;
  onClose: VoidFunction;
  selectedCenterId: string;
  selectedCenterPlanPrice: number;
};

function PayModal({
  open,
  onClose,
  selectedCenterId,
  selectedCenterPlanPrice,
}: Props) {
  const [createPayment, { loading, data }] = useMutation(CREATE_PLAN_PAYMENT);

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handlePay = ({ amount }: FieldValues) => {
    createPayment({
      variables: {
        center: selectedCenterId,
        amount,
      },
    });
  };

  useEffect(() => {
    if (!loading && data) {
      onClose();
      reset({});
      toast.success("Muvaqiyatli to'landi!");
    }
  }, [loading, data]);

  useEffect(() => {
    reset({ amount: selectedCenterPlanPrice });
  }, [selectedCenterPlanPrice]);

  return (
    <>
      {loading && <Loader />}
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{t("create payment")}</DialogTitle>
        <DialogContent>
          <Controller
            name="amount"
            rules={{
              required: true,
            }}
            control={control}
            render={({ field }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    label={t("price")}
                    type="number"
                    {...field}
                    error={!!errors[field.name]}
                    helperText={
                      !!errors[field.name] && t("Please input price!")
                    }
                  />
                </FormControl>
              );
            }}
          />
          {selectedCenterPlanPrice && (
            <div>
              <FormHelperText>
                {t("Plan price")}:{" "}
                {selectedCenterPlanPrice?.toLocaleString("Fi-fi")}
                uzs
              </FormHelperText>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            color="inherit"
            onClick={onClose}
            variant="contained"
          >
            {t("cancel")}
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit(handlePay)}
          >
            {t("pay")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default PayModal;
