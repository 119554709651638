import t from "translate";
import {
  Box,
  Card,
  CardContent,
  SvgIconTypeMap,
  Typography,
  colors,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

import StyledDashboardCard from "./Card.style";

type Props = {
  title: string;
  total: number;
  totalSubFix?: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
};

function DashboardCard({ icon: Icon, title, total, totalSubFix }: Props) {
  return (
    <StyledDashboardCard>
      <Card>
        <CardContent>
          <Box display="flex" alignItems="center" gap={2}>
            <Box className="card__icon-wrapper">
              <Icon fontSize="large" sx={{ color: colors.blue[700] }} />
            </Box>
            <Box>
              <Typography
                className="card__title"
                variant={`${
                  total?.toLocaleString("FI-fi")?.length > 9 ? "h5" : "h4"
                }`}
              >
                {total?.toLocaleString("FI-fi")} {totalSubFix}
              </Typography>
              <Typography variant="subtitle1">{t(title)}</Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </StyledDashboardCard>
  );
}

export default DashboardCard;
