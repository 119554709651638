import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { Box, Button, FormControl, TextField, Typography } from "@mui/material";

import t from "translate";
import { GET_DEFAULT_LANGUAGE, SIGN_IN } from "./api";
import Container from "UI/Container";
import Loader from "components/Loader";
import { convertPxToRem } from "utils";
import StyledSignIn from "./SignIn.style";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { setLang, setWords, signInAction } from "store/reducer/AuthSlice";

function SignIn() {
  const words = useAppSelector((store) => store.auth.words);

  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [getLang, { data: langData, loading: langLoading }] =
    useLazyQuery(GET_DEFAULT_LANGUAGE);

  const [signIn, { data, loading }] = useLazyQuery(SIGN_IN);

  const handleSingIn = ({ password, phone }: FieldValues) => {
    signIn({
      variables: {
        phone,
        password,
      },
    });
  };

  useEffect(() => {
    if (Object.values(words || {}).length === 0) {
      getLang();
    }
  }, []);

  useEffect(() => {
    if (!langLoading && langData) {
      dispatch(setWords(langData.getDefaultLanguage?.payload?.words));
      dispatch(setLang(langData.getDefaultLanguage?.payload?._id));
    }
  }, [langLoading, langData]);

  useEffect(() => {
    if (!loading && data) {
      dispatch(signInAction(data.signInAdminPanel));
    }
  }, [data, loading]);

  return (
    <StyledSignIn>
      <Container>
        {loading && <Loader />}
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box
            gap={3}
            width="100%"
            display="flex"
            component="form"
            alignItems="center"
            flexDirection="column"
            maxWidth={convertPxToRem(450)}
            onSubmit={handleSubmit(handleSingIn)}
          >
            <Typography variant="h4">{t("sign in")}</Typography>
            <Controller
              name="phone"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => {
                return (
                  <FormControl fullWidth>
                    <TextField
                      label={t("login")}
                      {...field}
                      type="text"
                      error={!!errors[field.name]}
                      helperText={
                        !!errors[field.name] && t("Please input password!")
                      }
                    />
                  </FormControl>
                );
              }}
            />
            <Controller
              name="password"
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <TextField
                    label={t("password")}
                    {...field}
                    type="password"
                    error={!!errors[field.name]}
                    helperText={
                      !!errors[field.name] && t("Please input password!")
                    }
                  />
                </FormControl>
              )}
            />
            <Button size="large" type="submit" fullWidth variant="contained">
              {t("sign in")}
            </Button>
          </Box>
        </Box>
      </Container>
    </StyledSignIn>
  );
}

export default SignIn;
