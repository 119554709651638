import { IRoute } from "interfaces/Route";
import Centers from "pages/Centers";
import paths from "constants/RoutePaths";
import SignIn from "pages/Signin";
import Plans from "pages/Plans";
import Langs from "pages/Langs";
import Dashboard from "pages/Dashboard";

const { SIGN_IN, CENTERS, PLANS, LANGS, DASHBOARD } = paths;

export const publicRoutes: IRoute[] = [
  {
    path: SIGN_IN,
    component: <SignIn />,
    key: "signIn",
  },
];

export const privateRoutes: IRoute[] = [
  {
    path: DASHBOARD,
    component: <Dashboard />,
    key: "dashboard",
  },
  {
    path: CENTERS,
    component: <Centers />,
    key: "centers",
  },
  {
    path: PLANS,
    component: <Plans />,
    key: "plans",
  },
  {
    path: LANGS,
    component: <Langs />,
    key: "langs",
  },
];
