import { IDropdownRoute } from "interfaces/Route";
import paths from "constants/RoutePaths";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";

const { CENTERS, PLANS, LANGS, DASHBOARD } = paths;

export const header_routes: IDropdownRoute[] = [
  {
    title: "dashboard",
    path: DASHBOARD,
    key: "dashboard",
    icon: <InsertChartOutlinedIcon />,
  },
  {
    title: "centers",
    path: CENTERS,
    key: "centers",
    icon: <SchoolOutlinedIcon />,
  },
  {
    title: "plans",
    path: PLANS,
    key: "plans",
    icon: <ReceiptOutlinedIcon />,
  },
  {
    title: "languages",
    path: LANGS,
    key: "langs",
    icon: <LanguageOutlinedIcon />,
  },
];
