import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { ICenter } from "interfaces/Centers";
import { convertPxToRem } from "utils";
import { getImageUrl } from "helpers/image";
import DefaultImg from "assets/images/Default.jpg";
import DescriptionText from "UI/DescriptionText";
import t from "translate";
import ConfirmModal from "components/ConfirmModal";
import { useMutation } from "@apollo/client";
import { DELETE_CENTER } from "pages/Centers/api";
import Loader from "components/Loader";
import { toast } from "react-toastify";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import PlusOutlinedIcon from "@mui/icons-material/Add";

type Props = {
  center: ICenter;
  onPay: VoidFunction;
  onAddKey: VoidFunction;
  refetch: VoidFunction;
};

function CenterCard({
  onPay,
  onAddKey,
  refetch,
  center: { description, thumbnail, name, logo, plan, _id },
}: Props) {
  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);

  const [deleteCenter, { data, loading }] = useMutation(DELETE_CENTER);

  const toggleDeleteModalVisible = () =>
    setIsDeleteModalVisible((prev) => !prev);

  const handleDelete = () => {
    deleteCenter({
      variables: {
        centerId: _id,
      },
    });
  };

  useEffect(() => {
    if (!loading && data) {
      refetch();
      toast.success(t("Center deleted successfully!"));
    }
  }, [data, loading]);

  return (
    <Card sx={{ height: "100%" }}>
      {loading && <Loader />}
      <CardMedia
        sx={{ height: 140 }}
        image={getImageUrl(thumbnail) || DefaultImg}
        title={name + " thumbnail"}
      />
      <Box sx={{ position: "relative", marginBottom: convertPxToRem(16) }}>
        <Avatar
          src={getImageUrl(logo)}
          sx={{
            position: "absolute",
            textTransform: "uppercase",
            left: convertPxToRem(10),
            width: convertPxToRem(50),
            height: convertPxToRem(50),
            top: "-" + convertPxToRem(24),
          }}
          alt={name + " logo"}
        >
          {name[0]}
        </Avatar>
      </Box>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {name}
        </Typography>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="body1" color="grey.800">
            {t("Plan price")}: {plan?.price?.toLocaleString() || 0} uzs
          </Typography>
          <DescriptionText text={description} />
          <Button
            fullWidth
            onClick={onPay}
            variant="contained"
            startIcon={<PaymentOutlinedIcon />}
          >
            {t("pay")}
          </Button>
          <Box display="flex" gap={1} mt={2}>
            <Button
              fullWidth
              color="error"
              variant="outlined"
              startIcon={<DeleteOutlinedIcon />}
              onClick={toggleDeleteModalVisible}
            >
              {t("delete")}
            </Button>
            <Button
              fullWidth
              onClick={onAddKey}
              variant="outlined"
              startIcon={<PlusOutlinedIcon />}
            >
              {t("Assign sms keys")}
            </Button>
          </Box>
        </Box>
      </CardContent>
      <ConfirmModal
        onConfirm={handleDelete}
        open={isDeleteModalVisible}
        onClose={toggleDeleteModalVisible}
      />
    </Card>
  );
}

export default CenterCard;
