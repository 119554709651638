import { gql } from "@apollo/client";

export const GET_PLANS = gql`
  query {
    plans: getPlans {
      payload {
        _id
        title
        price
        limits {
          employees
          students
          groups
        }
        currency
      }
    }
    basePlan: getBasePlan {
      payload {
        _id
        price
        employees
        groups
        prices {
          upTo
          price
        }
        currency
      }
    }
  }
`;

export const CREATE_PLAN = gql`
  mutation createPlan($data: PlanInput!) {
    createPlan(data: $data) {
      payload {
        _id
      }
    }
  }
`;

export const UPDATE_PLAN = gql`
  mutation updatePlan($planId: ID!, $data: UpdatePlanInput) {
    updatePlan(planId: $planId, data: $data) {
      payload {
        _id
      }
    }
  }
`;

export const DELETE_PLAN = gql`
  mutation deletePlan($planId: ID!) {
    deletePlan(planId: $planId) {
      payload {
        _id
      }
    }
  }
`;
