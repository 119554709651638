import StyleLoader from "./Style";
import { CircularProgress } from "@mui/material";

function Loader() {
  return (
    <StyleLoader>
      <CircularProgress size="large" />
    </StyleLoader>
  );
}

export default Loader;
