import React, { ReactNode } from "react";
import { Box, Divider, Typography } from "@mui/material";
import t from "translate";
import { convertPxToRem } from "utils";

interface Props {
  title: string;
  divider?: boolean;
  children?: ReactNode;
  translateTitle?: boolean;
  size?: "small" | "medium";
}

const PageHeader = ({
  title,
  divider = true,
  size = "medium",
  translateTitle = true,
  children,
}: Props) => {
  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
        gap={convertPxToRem(14)}
        sx={!divider ? { marginBottom: convertPxToRem(20) } : {}}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant={size === "small" ? "h5" : "h4"}>
          {translateTitle ? t(title) : title}
        </Typography>
        <Box display="flex" flexWrap="wrap" gap={convertPxToRem(14)}>
          {children}
        </Box>
      </Box>
      {divider && (
        <Divider
          sx={{
            marginBottom: convertPxToRem(40),
            marginTop: convertPxToRem(14),
          }}
        />
      )}
    </>
  );
};

export default PageHeader;
