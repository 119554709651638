import { gql } from "@apollo/client";

export const SIGN_IN = gql`
  query signInAdminPanel($phone: String!, $password: String!) {
    signInAdminPanel(phone: $phone, password: $password) {
      token
      user {
        _id
      }
    }
  }
`;

export const GET_DEFAULT_LANGUAGE = gql`
  query getDefaultLanguage {
    getDefaultLanguage {
      payload {
        _id
        lang
        words
      }
    }
  }
`;
