import { colors, createTheme } from "@mui/material";
import styleColors from "styles/colors";
import { convertPxToRem } from "utils";

export const theme = createTheme({
  components: {
    MuiButton: {
      variants: [
        {
          props: { size: "large" },
          style: {
            paddingTop: convertPxToRem(14),
            paddingBottom: convertPxToRem(14),
          },
        },
        {
          props: { size: "medium" },
          style: {
            paddingTop: convertPxToRem(10),
            paddingBottom: convertPxToRem(10),
          },
        },
        {
          props: { size: "small" },
          style: {
            paddingTop: convertPxToRem(6),
            paddingBottom: convertPxToRem(6),
          },
        },
      ],
      styleOverrides: {
        root: {
          textTransform: "capitalize",
        },
        outlinedWarning: {
          color: colors.amber[700],
          borderColor: colors.amber[400],
          ":hover": {
            backgroundColor: colors.amber[50] + "40",
            borderColor: colors.amber[600],
          },
        },
        containedWarning: {
          borderColor: colors.amber[600],
          backgroundColor: colors.amber[600],
          ":hover": {
            borderColor: colors.amber[700],
            backgroundColor: colors.amber[700],
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: `0 ${convertPxToRem(2)} ${convertPxToRem(10)} 0 ${
            styleColors.boxShadow
          }`,
          border: `${convertPxToRem(0.5)} solid ${styleColors.boxShadow}`,
        },
      },
    },
    MuiPaper: {
      variants: [
        {
          props: { variant: "elevation", elevation: 1 },
          style: {
            boxShadow: `0 ${convertPxToRem(2)} ${convertPxToRem(10)} 0 ${
              styleColors.boxShadow
            }`,
            border: `${convertPxToRem(0.5)} solid ${styleColors.boxShadow}`,
          },
        },
      ],
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          gap: convertPxToRem(20),
          width: convertPxToRem(520),
          paddingTop: convertPxToRem(20),
          paddingBottom: convertPxToRem(20),
          paddingLeft: convertPxToRem(24),
          paddingRight: convertPxToRem(24),
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 0,
          columnGap: convertPxToRem(6),
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 0,
          paddingTop: `${convertPxToRem(8)} !important`,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        inputSizeSmall: {
          paddingTop: `${convertPxToRem(10)} !important`,
          paddingBottom: `${convertPxToRem(10)} !important`,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow:
            "0px -2px 4px -1px rgba(0,0,0,0.2), 0px 0px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          textTransform: "capitalize",
        },
        colorWarning: {
          color: colors.amber[700],
          background: colors.amber[100],
        },
        colorSuccess: {
          color: colors.green[700],
          background: colors.green[100],
        },
        colorError: {
          color: colors.red[700],
          background: colors.red[100],
        },
        colorInfo: {
          color: colors.blue[700],
          background: colors.blue[100],
        },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          height: "100%",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          height: "100%",
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          marginTop: convertPxToRem(20),
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: convertPxToRem(16),
          borderTop: `1px solid rgba(0, 0, 0, .125)`,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          marginTop: convertPxToRem(8),
          marginLeft: convertPxToRem(8),
          marginBottom: convertPxToRem(8),
        },
        root: {
          flexDirection: "row-reverse",
          backgroundColor: "rgba(0, 0, 0, .03)",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: `1px solid #0000001f`,
          "&:not(:last-child)": {
            borderBottom: 0,
          },
          "&:before": {
            display: "none",
          },
        },
      },
    },
  },
});
