import { useAppSelector } from "hooks/redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { privateRoutes } from "routes";
import { publicRoutes } from "./routes";
import Header from "components/Header";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const store = useAppSelector((state) => state);
  const { token } = store.auth;

  if (token) {
    return (
      <>
        <ToastContainer />
        <Header />
        <Routes>
          {privateRoutes.map((route) => (
            <Route
              element={route.component}
              path={route.path}
              key={route.key}
            />
          ))}
        </Routes>
      </>
    );
  }
  return (
    <>
      <ToastContainer />
      <Routes>
        {publicRoutes.map((route) => (
          <Route element={route.component} path={route.path} key={route.key} />
        ))}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
}

export default App;
