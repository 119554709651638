import React, { CSSProperties } from "react";
import EmptyBox from "./assets/empty-box.png";
import { Box, Typography } from "@mui/material";
import t from "translate";
import { convertPxToRem } from "utils";

interface Props {
  title?: string;
  style?: CSSProperties;
}

const NoData = ({ title = "No data", style }: Props) => {
  return (
    <Box
      display="flex"
      gap={convertPxToRem(4)}
      alignItems="center"
      flexDirection="column"
      style={style}
    >
      <img
        src={EmptyBox}
        alt="empty-box"
        style={{ width: convertPxToRem(140), height: convertPxToRem(140) }}
      />
      <Typography variant="subtitle1">{t(title)}</Typography>
    </Box>
  );
};

export default NoData;
