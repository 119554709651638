import { gql } from "@apollo/client";

export const ADD_SMS_CONFIGS = gql`
  mutation addSMSSecretAndDevice(
    $center: ID!
    $secret: String!
    $deviceId: String!
  ) {
    addSMSSecretAndDevice(
      center: $center
      secret: $secret
      deviceId: $deviceId
    ) {
      payload
    }
  }
`;
