import { gql } from "@apollo/client";

export const GET_ALL_CENTERS = gql`
  query getAllCenters {
    getAllCenters {
      payload {
        _id
        name
        description
        logo
        thumbnail
        city
        email
        phone
        telegram
        plan {
          _id
          price
        }
      }
    }
  }
`;

export const CREATE_PLAN_PAYMENT = gql`
  mutation createPlanPayment($center: ID!, $amount: Long!) {
    createPlanPayment(center: $center, amount: $amount) {
      payload {
        amount
      }
    }
  }
`;

export const DELETE_CENTER = gql`
  mutation deleteCenter($centerId: ID!) {
    deleteCenter(centerId: $centerId) {
      payload {
        center {
          _id
        }
      }
    }
  }
`;
