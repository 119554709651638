import { createSlice } from "@reduxjs/toolkit";
import { IInitialState } from "interfaces/Redux";

const initialState: IInitialState = {
  center: null,
  groupId: null,
  permission: null,
  homeworkId: null,
  courseId: null,
};

const center = createSlice({
  name: "center",
  initialState,
  reducers: {
    addCenter(state, action) {
      state.center = action.payload;
    },
    removeCenter(state) {
      state.center = null;
      state.groupId = null;
      state.permission = null;
      state.homeworkId = null;
    },
    addGroupID(state, action) {
      state.groupId = action.payload;
    },
    addCourseID(state, action) {
      state.courseId = action.payload;
    },
    addPermission(state, action) {
      state.permission = action.payload;
    },
    addHomework(state, action) {
      state.homeworkId = action.payload;
    },
  },
});

export default center.reducer;

export const {
  addCenter,
  removeCenter,
  addGroupID,
  addPermission,
  addHomework,
  addCourseID,
} = center.actions;
